(function () {
    $body = $('body')
    $wrapper = $('.wrapper')
    if (!$body.hasClass('no-page-loader')) {
        var pageInAnimation = $body.attr('data-animation-in') || "fadeIn",
            pageOutAnimation = $body.attr('data-animation-out') || "fadeOut",
            pageLoaderStylePath = $body.attr('data-animation-icon-path') || "/images/svg-loaders/",
            pageLoaderStyle = $body.attr('data-animation-icon') || "ring.svg",
            pageInDuration = $body.attr('data-speed-in') || 1000,
            pageOutDuration = $body.attr('data-speed-out') || 500;

        $wrapper.animsition({
            inClass: pageInAnimation,
            outClass: pageOutAnimation,
            inDuration: pageInDuration,
            outDuration: pageOutDuration,
            loading: true,
            loadingParentElement: 'body', //animsition wrapper element
            loadingClass: 'animsition-loading',
            loadingInner: '', // e.g '<img src="loading.svg" />'
            timeout: true,
            timeoutCountdown: 2000,
            onLoadEvent: true,
            browser: ['animation-duration', '-webkit-animation-duration'],
            // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
            // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
            overlay: false,
            overlayClass: 'animsition-overlay-slide',
            overlayParentElement: 'body',
            transition: function (url) {
                window.location.href = url;
            }

        });

    }
})();