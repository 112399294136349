$(document).on('ready', function() {
    if ($().fitVids) {
        $(".container").fitVids();
    }
    // browser window scroll (in pixels) after which the "back to top" link is shown
    var offset = 300,
        //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
        offset_opacity = 1200,
        //duration of the top scrolling animation (in ms)
        scroll_top_duration = 700,
        //grab the "back to top" link
        $back_to_top = $('.cd-top')

    //hide or show the "back to top" link
    $(window).scroll(function(){
        ( $(this).scrollTop() > offset ) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out')
        if( $(this).scrollTop() > offset_opacity ) {
            $back_to_top.addClass('cd-fade-out')
        }
    })

    //smooth scroll to top
    $back_to_top.on('click', function(event){
        event.preventDefault()
        $('body,html').animate({
                scrollTop: 0 ,
            }, scroll_top_duration
        )
    })

    let $owl = $('.owl-carousel').owlCarousel({
        animateOut: 'fadeOut',
        loop: true,
        margin:0,
        nav:true,
        autoplay: true,
        autoplayTimeout: 5000,
        stopOnHover : true,
        navText:['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        items: 1
    })


    $('.video-slick-center').slick({
        centerMode: true,
        slidesToShow: 1,
        centerPadding: '300px',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: true,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    dots: true,
                    centerMode: true,
                    centerPadding: '0',
                    slidesToShow: 1
                }
            }
        ]
    })

    $('.slider-dr').slick({
        slidesToShow: 3,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3500,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    dots: false,
                    slidesToShow: 1
                }
            }
        ]
    })



    $('.video-slick-center').on('init', function (event, slick) {
    }).on('afterChange', function (e, o) {
        $('iframe').each(function () {
            $(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*')
        })
    })

    $('.testimonial').owlCarousel({
        loop:true,
        margin:10,
        nav:false,
        dots: true,
        autoplay: true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
    })

    if( $( window ).width() >= "768" ) {
        $(".header-wrapper").sticky({topSpacing:0})
    }

    $('#inquiry-form').submit(function () {
      fbq('track', 'Contact')
    })

})